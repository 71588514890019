<template>
  <div class="progress-bar" :class="{'with-padding': withPadding }">
    <div class="literals" v-if="!noLiterals">
      <span class="bar-literal eval">{{$t('PROGRESS_BAR.EVALUATED')}}</span>
      <span class="bar-literal total">{{$t('PROGRESS_BAR.TOTAL')}}</span>
    </div>
    <div class="total-bar">
      <div class="progres" :style="{ width: `${percent}%`}" :class="{'is-buit': value === 0}">
        <span class="number-value" :class="{'is-buit': value === 0}" v-if="value !== total">{{ value }}</span>
        <span class="number-value" :class="{'finished': value === total}" v-if="value === total">{{$t('PROGRESS_BAR.COMPLETED')}}</span>
      </div>
      <span class="total-value" :class="{'finished': value === total}">{{ total }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    value: Number,
    total: Number,
    withPadding: Boolean,
    noLiterals: Boolean
  },
  computed: {
    percent() {
      return (this.value / this.total) * 100;
    }
  }
};
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';

.progress-bar.with-padding {
  padding: 1rem 2rem 1rem 1rem;
}

.total-bar {
  height: 30px;
  background-color: rgba(71, 70, 82, 0.1);
  position: relative;
  border-radius: 5px;
}

.literals {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bar-literal {
  color: black;
  font-size: .8rem;
  font-weight: 500;
}

.progres {
  background-color: $green;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: .5rem;
  min-width: 7%;
  border-radius: 5px;
}

.progres.is-buit {
  background-color: transparent;
}

.number-value {
  color: white;
  font-weight: bold;
}

.number-value.is-buit {
  color: #302783;
}

.number-value.finished {
  font-weight: 400;
  font-size: .9rem;
}

.total-value {
  color: black;
  /* color: rgba(48, 39, 131, .4); */
  font-weight: bold;
  position: absolute;
  top: 3px;
  right: 8px;
}

.total-value.finished {
  color: white;
  font-weight: bold;
}

.number-value.finished em {
  font-weight: 400;
}

.finished .progres {
  justify-content: center;
}
</style>
